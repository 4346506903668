<template>
  <v-container
    class="align-stretch layout-container"
  >
    <BaseHeader
      title="Ihr persönlicher Performance Report"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Der Performance Report liefert genaue Auskünfte zum Erfolg Ihrer Google - und Facebook-Kampagnen.</p>
      </template>
    </BaseHeader>

    <PerformanceReport v-if="isExpert" />
    <v-alert
      v-else
      icon="mdi-information-outline"
      text
      type="info"
    >
      Die BOTTIMMO Rakete hat mehr Schub, wenn Sie gezielte Werbung für Ratgeber und Immobilienbewertung machen. Ads bei Facebook und Google liefern messbare Ergebnisse.
      Gerne zeigen wir Ihnen alle Möglichkeiten und steuern diese Werbung für Sie aus.<br>
      <a
        :href="contactUrl"
        target="_blank"
        rel="noopener noreferrer"
      > Jetzt beraten lassen.</a>
    </v-alert>
  </v-container>
</template>

<script>
import PerformanceReport from '@/modules/performanceReport'
import { isExpertUser } from '@/lib/productTypes'
import featureNames from '@/lib/featureNames'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'

export default {
  components: {
    PerformanceReport,
    BaseHeader,
    HeaderDesign
  },
  computed: {
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    contactUrl () {
      return this.$features.feature(featureNames.SUPPORT_CONTACT).config.calendly.fifteen
    }
  }
}
</script>
