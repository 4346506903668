<template>
  <v-card
    outlined
    class="px-2 pt-2 px-md-4"
  >
    <v-tabs
      v-model="tab"
    >
      <v-tab v-if="googleSrc">
        Google
      </v-tab>
      <v-tab v-if="fbSrc">
        Facebook
      </v-tab>
    </v-tabs>

    <v-divider />

    <v-tabs-items
      v-model="tab"
      class="pt-4"
    >
      <v-tab-item>
        <iframe
          :src="googleSrc"
          class="iframe"
          allowfullscreen
        />
      </v-tab-item>
      <v-tab-item>
        <iframe
          :src="fbSrc"
          class="iframe"
          allowfullscreen
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

export default {
  data: function () {
    return {
      tab: null
    }
  },
  computed: {
    googleSrc () {
      return 'https://datastudio.google.com/embed/reporting/9a7ca505-f522-4c9e-acff-13473858c7b7/page/jNnXB'
    },
    fbSrc () {
      return 'https://datastudio.google.com/embed/reporting/9a7ca505-f522-4c9e-acff-13473858c7b7/page/PTZWB'
    }
  }
}
</script>

<style scoped>
.iframe{
  padding: 0px;
  margin: 0px;
}
</style>
